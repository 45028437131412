import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import restEnLang from './locales/en/rest.en.json'
import restIdLang from './locales/id/rest.id.json'

/**
 * Login page and its components localization
 */
import loginEnLang from './locales/en/login.en.json'
import loginIdLang from './locales/id/login.id.json'

/**
 * Team page and its components localization
 */
import teamEnLang from './locales/en/team.en.json'
import teamIdLang from './locales/id/team.id.json'

/**
 * Invitation page and its components localization
 */
import invitationEnLang from './locales/en/invitation.en.json'
import invitationIdLang from './locales/id/invitation.id.json'

/**
 * Bulletin page and its components localization
 */
import bulletinEnLang from './locales/en/bulletin.en.json'
import bulletinIdLang from './locales/id/bulletin.id.json'

const resources = {
    en: {
        translation: {
            ...loginEnLang,
            ...teamEnLang,
            ...invitationEnLang,
            ...bulletinEnLang,
            ...restEnLang
        },
    },
    id: {
        translation: {
            ...loginIdLang,
            ...teamIdLang,
            ...invitationIdLang,
            ...bulletinIdLang,
            ...restIdLang
        },
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'id',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
