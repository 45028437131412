import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { theme } from './theme'
import 'material-symbols'
import 'react-quill/dist/quill.snow.css'
import { Provider } from 'react-redux'
import { store } from './redux'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClientProvider, QueryClient } from 'react-query'
import './utils/localization/localization.util'

console.log({ theme })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ''}>
                <Provider store={store}>
                    <BrowserRouter>
                        <ChakraProvider theme={theme} resetCSS>
                            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                            <App />
                        </ChakraProvider>
                    </BrowserRouter>
                </Provider>
            </GoogleOAuthProvider>
        </QueryClientProvider>
    </React.StrictMode>

    // <QueryClientProvider client={queryClient}>
    //     <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ''}>
    //         <Provider store={store}>
    //             <BrowserRouter>
    //                 <ChakraProvider theme={theme} resetCSS>
    //                     <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    //                     <App />
    //                 </ChakraProvider>
    //             </BrowserRouter>
    //         </Provider>
    //     </GoogleOAuthProvider>
    // </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()